<template>
  <div>
    <!-- Set Local Engine Folder -->
    <div class="form-inline sub-mr-2 sub-mt-2">
      <div>Set Local Engine Folder:</div>
      <b-form-input class="flex-grow-1" v-model="enginesPath"
        placeholder="Unreal Engine parent folder (where UE_5.3 folder is)" />
      <button class="btn btn-primary" @click="saveEnginePath" :disabled="!isValidPath(enginesPath)">Save</button>
    </div>
    <!-- List available engines  -->
    <div class="form-inline sub-mr-2 sub-mt-2">
      <div>Select Engine</div>
      <b-select v-model="selectedEngine" @change="loadEngine">
        <b-select-option v-for="engine in engines" :key="engine.id" :value="engine">{{ engine.id }}</b-select-option>
      </b-select>
    </div>
    <div class="form-inline sub-mr-2 sub-mt-2">
      <!-- If engine is installed cannot install -->
      <button class="btn btn-primary" @click="installEngine"
        :disabled="!isValidPath(enginesPath) || engineInstalled">Install Engine</button>
      <!-- Run -->
      <button class="btn btn-primary" @click="runEngine" :disabled="!engineInstalled">Launch</button>
      <div v-if="engineInstalled">Engine is installed</div>
    </div>
  </div>
</template>

<script>
import { getLog } from '@/services/log';
let log = getLog('sget-engines');
import { db } from '@/services/db';
import { checkFolderExists, isValidPath } from './shivarun';

export default {
  data() {
    return {
      engines: [],
      selectedEngine: null,
      enginesPath: '',
      engineInstalled: false,
    }
  },
  async mounted() {
    log.log('mounted');
    this.init();
  },
  methods: {
    isValidPath,
    async init() {
      log.log('init');
      let doc = await db.collection('ShivaGetUserConfigs').doc(this.$store.account.uid).get()
      if (doc.exists)
        this.enginesPath = doc.data().enginesPath;
      await this.$bind("engines", db.collection('ShivaGetEngines').where('display', '==', true));
      log.log('engines', this.engines);
      if (this.engines.length) {
        this.selectedEngine = this.engines[0];
        this.loadEngine();
      }
    },
    async loadEngine() {
      log.log('loadEngine', this.selectedEngine);
      let path = `${this.enginesPath}\\${this.selectedEngine.id}`;
      this.engineInstalled = await checkFolderExists(path);
      log.log('engineInstalled', path, this.engineInstalled);
    },
    saveEnginePath() {
      log.log('saveEnginePath', this.enginesPath);
      db.collection('ShivaGetUserConfigs').doc(this.$store.account.uid).set({ enginesPath: this.enginesPath }, { merge: true });
    },
    installEngine() {
      log.log('installEngine', this.selectedEngine);
      this.$emit('run-command', 'ShivaGetEngine.bat',
        [this.selectedEngine.id, this.enginesPath],
        { windowsVerbatimArguments: true }, () => {
          this.loadEngine();
        });
    },
    runEngine() {
      log.log('runEngine', this.selectedEngine);
      let path = (this.enginesPath + "\\" + this.selectedEngine.id + "\\" + this.selectedEngine.executablePath).replace(/\\\\/g, '\\');
      this.$emit('run-command', path,
        [],
        { windowsVerbatimArguments: true });
    }
  }
}

</script>